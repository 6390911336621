import { get, post } from '@/service/api';

export async function getModalTypes(id) {
  const resp = await get(`ui-integrator/modal-types/${id}`);
  return resp;
}

export async function listModalTypes(param, filter, itemsPerPage) {
  const data = await get(`ui-integrator/modal-types/fetch?size=${itemsPerPage ? `${itemsPerPage}` : '20'}&filter=${param ? `${filter} = !${param}` : ''}`);
  return data;
}
