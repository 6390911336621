<template>
  <FormContent :selected="selected" class="pa-6" background-color="transparent">
    <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab href="#tab1">CARTÕES BANDEIRADOS</v-tab>
        <v-tab href="#tab2">CARTÃO KOERICH</v-tab>
        <v-tab href="#tab3">DESCONTOS</v-tab>
      </v-tabs>
    </v-col>
    <v-tabs-items v-model="tab" class="background-default">
      <v-tab-item v-if="form.installmentConfig" value="tab1" class="pa-6" style="background-color: #ffffff00">
        <v-row class="margin-fix pa-6 pb-3">
          <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
            <TextFieldInput
              v-model="form.installmentConfig.initialDate"
              inputType="date"
              @focusout="validate('installmentConfig.initialDate')"
              label="Data inicial*"
              placeHolder="Informe a data"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-spacer />
          </v-col>
          <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
            <TextFieldInput
              v-model="form.installmentConfig.finalDate"
              label="Data final*"
              placeHolder="Informe a data"
              @focusout="validate('installmentConfig.finalDate')"
              inputType="date"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
        </v-row>
        <v-row class="margin-fix pa-6 pb-0">
          <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
            <TextFieldInput
              v-model="form.installmentConfig.minValue"
              label="Valor mínimo parcelamento*"
              inputType="number"
              @focusout="validate('installmentConfig.minValue')"
              placeHolder="Informe o valor (R$)"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-spacer />
          </v-col>
          <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
            <ComboboxInput
              @input="changeNumberInstallments($event, 'installmentConfig', 'cardList')"
              v-model="form.installmentConfig.maxInstallments"
              label="Nº máximo de parcelas*"
              placeHolder="Selecione a quantidade"
              :options="itens"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
        </v-row>

        <v-row class="margin-fix pa-3 pb-3" style="white-space: nowrap">
          <v-col v-if="form.installmentConfig.maxInstallments" xl="12" lg="12" sm="12" md="12" class="pb-0">
            <h4 class="subtext subtext_form_devide_h4 mb-0">Taxa de juros e coeficientes do parcelamento*</h4>
          </v-col>
          <v-col v-if="form.installmentConfig.maxInstallments" xl="12" lg="12" sm="12" md="12" class="pb-0">
            <DataCrud
              v-if="form.installmentConfig.maxInstallments"
              :table-header="header"
              :columnDecorators="decorators"
              :tableData="cardList.list"
              :justify-center="false"
              :enableAddFunction="!readOnly"
              :enableEditFunction="!readOnly"
              :showFormDialog="true"
              @changeFormatterInput="changeInterestRates($event, form.installmentConfig)"
              @changePage="changePage(form.installmentConfig.installments, 'cardList', $event)"
              @changeItemsPerPage="changeItemsPerPage(form.installmentConfig.installments, 'cardList', $event)"
              :server-items-length="form.installmentConfig.installments.length"
            >
            </DataCrud>
          </v-col>
          <h6 v-else class="subtext subtext_form_devide_h6" style="margin-top: 1rem">
            É necessário primeiramente selecionar o número máximo de parcelas
          </h6>
          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
            <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="reset" v-show="!readOnly">
              Redefinir condições
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="form.installmentConfigKoerich" value="tab2" class="pa-6" style="    background-color: #ffffff00">
        <v-row class="margin-fix pa-6 pb-0">
          <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
            <TextFieldInput
              v-model="form.installmentConfigKoerich.initialDate"
              inputType="date"
              @focusout="validate('installmentConfigKoerich.initialDate')"
              label="Data inicial*"
              placeHolder="Informe a data"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-spacer />
          </v-col>
          <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
            <TextFieldInput
              v-model="form.installmentConfigKoerich.finalDate"
              label="Data final*"
              placeHolder="Informe a data"
              @focusout="validate('installmentConfigKoerich.finalDate')"
              inputType="date"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
        </v-row>
        <v-row class="margin-fix pa-6 pb-0">
          <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
            <TextFieldInput
              v-model="form.installmentConfigKoerich.minValue"
              label="Valor mínimo parcelamento*"
              inputType="number"
              @focusout="validate('installmentConfigKoerich.minValue')"
              placeHolder="Informe o valor (R$)"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-spacer />
          </v-col>
          <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
            <ComboboxInput
              @input="changeNumberInstallments($event, 'installmentConfigKoerich', 'koerichList')"
              v-model="form.installmentConfigKoerich.maxInstallments"
              label="Nº máximo de parcelas*"
              placeHolder="Selecione a quantidade"
              :options="itens"
              labelWidth="20%"
              :readOnly="readOnly"
            />
          </v-col>
        </v-row>
        <v-row class="margin-fix pa-3 pb-3" style="white-space: nowrap">
          <v-col v-if="form.installmentConfigKoerich.maxInstallments" xl="12" lg="12" sm="12" md="12" class="pb-0">
            <h4 class="subtext subtext_form_devide_h4 mb-0">Taxa de juros e coeficientes do parcelamento*</h4>
          </v-col>
          <v-col v-if="form.installmentConfigKoerich.maxInstallments" xl="12" lg="12" sm="12" md="12" class="pb-0">
            <DataCrud
              :columnDecorators="decorators"
              :table-header="header"
              :tableData="koerichList.list"
              :justify-center="false"
              :enableAddFunction="!readOnly"
              :enableEditFunction="!readOnly"
              :enableRemoveFunction="!readOnly"
              :showFormDialog="true"
              @changeFormatterInput="changeInterestRates($event, form.installmentConfigKoerich)"
              @changePage="changePage(form.installmentConfigKoerich.installments, 'koerichList', $event)"
              @changeItemsPerPage="changeItemsPerPage(form.installmentConfigKoerich.installments, 'koerichList', $event)"
              :server-items-length="form.installmentConfigKoerich.installments.length"
            >
            </DataCrud>
          </v-col>

          <h6 v-else class="subtext subtext_form_devide_h6" style="margin-top: 1rem">
            É necessário primeiramente selecionar o número máximo de parcelas
          </h6>

          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
            <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="reset" v-show="!readOnly">
              Redefinir condições
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab3" class="pa-6" style="    background-color: #ffffff00">
        <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="add()" v-show="!readOnly">
          + DESCONTO
        </v-btn>
        <DataCrud
          v-if="form.discountsByPaymentType.length > 0"
          :table-header="headerDiscounts"
          :tableData="form.discountsByPaymentType"
          :justify-center="false"
          :enableAddFunction="!readOnly"
          :enableRemoveFunction="!readOnly"
          :enableEditFunction="!readOnly"
          :showFormDialog="true"
          @editEvent="edit"
          @formDelete="remove"
          :column-decorators="decoratorsDiscounts"
          :server-items-length="form.discountsByPaymentType.length"
        >
        </DataCrud>
        <div v-else>
          <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">
            <p>Ainda não existem descontos para nenhum meio de pagamento.</p>
          </span>
        </div>
        <v-col cols="1">
          <v-spacer />
        </v-col>

        <v-dialog v-model="formDiscount" max-width="70%">
          <v-card class="dialog-content">
            <v-card-title style="padding-top: 20px;">
              <h3 data-testid="page-title" class="description">
                Cadastro desconto de meio de pagamento
              </h3>
              <v-spacer />
              <v-btn color="#07BEE7" icon dark @click.stop="formDiscount = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row class="margin-fix pa-6 pb-0">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                  <ComboboxInput
                    label-width="20%"
                    v-model="discount.paymentType"
                    persistent-hint
                    label="Meio de pagamento*"
                    :validationRules="[validadePaymentType]"
                    placeHolder="Selecione ou digite"
                    :options="paymentTypeOptions"
                    :readOnly="readOnly"
                  />
                </v-col>
                <v-col cols="1">
                  <v-spacer />
                </v-col>
                <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                  <TextFieldInput
                    v-model="discount.discountPercentage"
                    inputType="number"
                    label="Desconto (%)*"
                    :validationRules="[validadeDiscountPercentage]"
                    placeHolder="Informe o percentual"
                    labelWidth="20%"
                    :readOnly="readOnly"
                  />
                </v-col>
              </v-row>
              <v-row class="margin-fix pa-6 pb-0">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                  <TextFieldInput
                    v-model="discount.initialDate"
                    inputType="date"
                    label="Data inicial*"
                    :validationRules="[validadeBeforeDateDiscont]"
                    placeHolder="Informe a data"
                    labelWidth="20%"
                    :readOnly="readOnly"
                  />
                </v-col>
                <v-col cols="1">
                  <v-spacer />
                </v-col>
                <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                  <TextFieldInput
                    v-model="discount.finalDate"
                    inputType="date"
                    label="Data final*"
                    :validationRules="[validadeAfterDateDiscont]"
                    placeHolder="Informe a data"
                    labelWidth="20%"
                    :readOnly="readOnly"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions style="padding: 0 30px 30px 30px;">
              <v-spacer />
              <v-btn color="#07BEE7" outlined @click.stop="formDiscount = false">Cancelar</v-btn>
              <v-btn color="#07BEE7" outlined @click.stop="addItem" :disabled="validadeFormDiscont || readOnly">Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs-items>
  </FormContent>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import DataCrud from '../../../components/template/crud/DataCrud';
import { get } from '../../../service/api';
import ProductFormMixin from './mixins/ProductFormMixin';

export default {
  components: {
    FormContent,
    DataCrud,
    TextFieldInput,
    ComboboxInput,
  },
  mixins: [ProductFormMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Cartões e boletos',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },

  data() {
    return {
      headerDiscounts: [
        { text: 'Meio de pagamento', value: 'paymentType' },
        { text: 'Desconto (%)', value: 'discountPercentage' },
        { text: 'Data inicial', value: 'initialDate' },
        { text: 'Data final', value: 'finalDate' },
      ],
      decoratorsDiscounts: [
        {
          column: 'initialDate',
          chipBackgroundColorFunction: (item) => '#e0e0e000',
          formatterFunction: (item) => {
            const date = new Date(item.initialDate);
            return [date.getDate() + 1, date.getMonth() + 1, date.getFullYear()].join('/');
          },
        },
        {
          column: 'finalDate',
          chipBackgroundColorFunction: (item) => '#e0e0e000',
          formatterFunction: (item) => {
            const date = new Date(item.finalDate);
            return [date.getDate() + 1, date.getMonth() + 1, date.getFullYear()].join('/');
          },
        },
      ],
      paymentTypeOptions: ['pix', 'boleto'],
      discount: {},
      discountRaw: {},
      formDiscount: false,
      decorators: [
        {
          column: 'interestRates',
          formatterImput: {
            param: 'interestRates',
            type: 'number',
          },
          disabled: this.$props.readOnly,
        },
      ],
      selected: false,
      formList: {
        list: [],
        page: 1,
        itemsPage: 10,
      },
      koerichList: {
        list: [],
        page: 1,
        itemsPage: 10,
      },
      cardList: {
        list: [],
        page: 1,
        itemsPage: 10,
      },
      formDialog: {
        show: false,
        initialDate: null,
        finalDate: null,
        minValue: null,
        maxInstallments: null,
        installments: [],
      },
      tab: null,
      tabsArray: ['tab', 'tab1', 'tab2'],
      header: [
        { text: 'Parcela', value: 'installmentNumber' },
        { text: 'Taxa de juros (%)', value: 'interestRates' },
        { text: 'Coeficiente do parcelamento (%)', value: 'coefficients' },
      ],
      itens: [],
      maxOption: 100,
    };
  },
  computed: {
    validadeFormDiscont() {
      return (
        !this.discount ||
        !this.discount.finalDate ||
        !this.discount.initialDate ||
        !this.discount.discountPercentage ||
        !this.discount.paymentType ||
        typeof this.validadeBeforeDateDiscont(this.discount?.initialDate) === 'string' ||
        typeof this.validadeAfterDateDiscont(this.discount?.finalDate) === 'string'
      );
    },
  },
  methods: {
    validadePaymentType(value) {
      return (value && value.length > 0) || 'Campo `Meio de Pagamento` é Obrigatório.';
    },
    validadeDiscountPercentage(value) {
      return (value && value.length > 0) || 'Campo `Desconto` é Obrigatório.';
    },
    validadeBeforeDateDiscont(value) {
      return this.validadeBeforeDate(value, this.discount?.finalDate);
    },
    installmentConfigInitialDateValid(value) {
      return this.validadeBeforeDate(value, this.form.installmentConfig?.finalDate);
    },
    installmentConfigKoerichInitialDateValid(value) {
      return this.validadeBeforeDate(value, this.form.installmentConfigKoerich?.finalDate);
    },
    validadeAfterDateDiscont(value) {
      return this.validadeAfterDate(value, this.discount?.initialDate);
    },
    installmentConfigFinalDateValid(value) {
      return this.validadeAfterDate(value, this.form.installmentConfig?.initialDate);
    },
    installmentConfigKoerichFinalDateValid(value) {
      return this.validadeAfterDate(value, this.form.installmentConfigKoerich?.initialDate);
    },
    validadeBeforeDate(value, after) {
      return !(value && value.length > 0)
        ? 'Campo `Data Inicial` é Obrigatório.'
        : new Date(value) <= new Date(after) || !after || 'Campo `Data Inicial` deve ser inferior `Data Final`.';
    },
    validadeAfterDate(value, before) {
      return !(value && value.length > 0)
        ? 'Campo `Data Final` é Obrigatório.'
        : new Date(value) >= new Date(before) || !before || 'Campo `Data Final` deve ser superior `Data Inicial`.';
    },
    async add() {
      this.discount = {};
      this.formDiscount = true;
    },
    async edit(item) {
      this.discountRaw = item;
      this.discount = { ...item };
      this.formDiscount = true;
    },
    async addItem() {
      if (!this.discount.id) {
        this.discount.id = uuidv4();
        this.form.discountsByPaymentType.push(this.discount);
      } else {
        this.discountRaw.discountPercentage = this.discount.discountPercentage;
        this.discountRaw.initialDate = this.discount.initialDate;
        this.discountRaw.finalDate = this.discount.finalDate;
        this.discountRaw.paymentType = this.discount.paymentType;
      }
      this.formDiscount = false;
    },
    async remove(item) {
      this.form.discountsByPaymentType = this.form.discountsByPaymentType.filter((data) => data.id !== item.id);
    },
    validate(field) {
      this.$emit('validateRequired', 'CardsAndBilletProductForm', field, this.form);
    },
    async reset() {
      if (this.$route.params.id && this.$route.params.id !== 'undefined') {
        const { data } = await get(`ui-integrator/products/${this.$route.params.id}`);
        this.form.installmentConfigKoerich = data.installmentConfigKoerich;
        this.form.installmentConfig = data.installmentConfig;
        if (this.form.installmentConfigKoerich) {
          this.changePage(this.form.installmentConfigKoerich.installments, 'koerichList', 1);
        }
        if (this.form.installmentConfig) {
          this.changePage(this.form.installmentConfig.installments, 'cardList', 1);
        }
      } else {
        this.form.installmentConfigKoerich = {
          installments: [],
        };
        this.form.installmentConfig = {
          installments: [],
        };
      }
      this.validateInstallmentConfig = true;
      this.validateInstallmentConfigKoerich = true;
    },
    changeInterestRates(value, list) {
      // eslint-disable-next-line vue/max-len
      const installment = list.installments.filter((data) => value.item.installmentNumber === data.installmentNumber)[0];
      const { interestRates } = installment;
      if (Number(interestRates) < 0) {
        installment.interestRates = '0';
      }
      installment.coefficients = this.calculaCoeficiente(installment);
    },
    calculaCoeficiente(installment) {
      if (installment.interestRates <= 0) {
        return 0;
      }

      const interestRates = installment.interestRates / 100;
      const { installmentNumber } = installment;

      return (interestRates / (1 - (1 + interestRates) ** -installmentNumber)).toFixed(4);
    },
    changePage(installments, list, page) {
      this[list].page = page;
      const { itemsPage } = this[list];
      this[list].list = installments.slice(itemsPage * (page - 1), itemsPage * (page - 1) + itemsPage);
    },
    changeItemsPerPage(installments, list, itemsPage) {
      this[list].itemsPage = itemsPage;
      this[list].page = 1;
      this[list].list = installments.slice(0, itemsPage);
    },
    changeNumberInstallments(number, type, page) {
      if (this.form[type].installments.length < number) {
        // eslint-disable-next-line no-plusplus
        for (let i = this.form[type].installments.length + 1; i <= number; i++) {
          this.form[type].installments.push({
            installmentNumber: i,
            interestRates: 0,
            coefficients: 0,
          });
        }
      }
      if (this.form[type].installments.length > number) {
        // eslint-disable-next-line no-plusplus
        for (let i = this.form[type].installments.length; i > number; i--) {
          this.form[type].installments.pop();
        }
      }
      this[page].page = 1;
      this[page].itemsPage = 10;
      this.changePage(this.form[type].installments, page, 1);
    },
  },
  mounted() {
    if (!this.form.installmentConfigKoerich) {
      this.form.installmentConfigKoerich = {
        installments: [],
      };
    }
    if (!this.form.installmentConfig) {
      this.form.installmentConfig = {
        installments: [],
      };
    }
    if (!this.form.boletoConfig) {
      this.form.boletoConfig = {};
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < this.maxOption; i++) {
      this.itens.push(i);
    }
    this.changePage(this.form.installmentConfigKoerich.installments, 'koerichList', 1);
    this.changePage(this.form.installmentConfig.installments, 'cardList', 1);
  },
};
</script>

<style></style>
