<template>
  <FormContent :selected="selected" class="pa-6">
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          label-width="40%"
          label="Número de vendas"
          placeHolder="Informe a quantidade"
          inputType="number"
          v-model="form.salesPotential"
          :readOnly="readOnly"
        />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          paddingLeft="5%"
          label-width="30%"
          v-model="form.releaseDate"
          label="Data de lançamento"
          placeHolder="Informe a data"
          inputType="date"
          :readOnly="readOnly"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col cols="12" class="pb-0">
        <TextFieldInput
          label-width="20%"
          v-model="form.mediaUrl"
          @focusout="validate('mediaUrl')"
          :validationRules="[validationRules.mediaUrl]"
          label="URL do vídeo"
          placeHolder="Informe a URL do vídeo no youtube"
          :readOnly="readOnly"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <SwitchInput label-width="40%" label="Venda permitida?" inputType="text" v-model="form.allowedSale" :readOnly="readOnly"/>
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          label-width="40%"
          label="Quant. máxima por periodo"
          placeHolder="Informe a quantidade"
          inputType="number"
          v-model="form.orderLimit"
          :readOnly="readOnly"
        />
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import ProductFormMixin from './mixins/ProductFormMixin';

export default {
  mixins: [ProductFormMixin],
  components: {
    FormContent,
    TextFieldInput,
    SwitchInput,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Vendas',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },
  methods: {
    validate(field) {
      if (field.includes('.')) {
        this.$emit('validateRequired', 'SalesProductForm', field, this.form[field.split('.')[0]][field.split('.')[1]]);
      } else {
        this.$emit('validateRequired', 'SalesProductForm', field, this.form[field]);
      }
    },
  },
  data() {
    return {
      selected: false,
      icon: 'mdi-cube-outline',
    };
  },
};
</script>
