<template>
  <BaseDialogSign rgbaSignBorderColor="rgba(225, 170, 121, 0.7)" hexSignCoreColor="#E1AA79" ></BaseDialogSign>
</template>

<script>

import BaseDialogSign from '@/components/utils/dialog/dialogSign/BaseDialogSign';

export default {
  name: 'DangerSign',
  components: { BaseDialogSign },
};

</script>
