<template>
  <BaseDialogSign rgbaSignBorderColor="rgba(255,215,94,0.7)" hexSignCoreColor="#ffc107" ></BaseDialogSign>
</template>

<script>

import BaseDialogSign from '@/components/utils/dialog/dialogSign/BaseDialogSign';

export default {
  name: 'WarningSign',
  components: { BaseDialogSign },
};

</script>
