<template>
  <v-dialog
    v-model='show'
    persistent
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    content-class="alert-card-dialog">
    <template>
      <v-card :width="dialogWidth" color="alert-card">
        <v-card-text>
          <v-row>
            <v-col></v-col>
            <v-col cols="1">
              <v-btn
                icon
                dark
                @click="close"
                color='primary'>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col xs="7" md="5" style="text-align: center">
              <DangerSign v-if="this.dialogType === 'danger'"></DangerSign>
              <WarningSign v-if="this.dialogType === 'warning'"></WarningSign>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col xs="6" md="4">
              <p class="alert-warning-title">
                Atenção!
              </p>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col xs="12" md="10">
              <div class="alert-warning-text">
                <slot name="message">
                  This will only be displayed if there is no content
                  to be distributed.
                </slot>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-row justify="center">
            <v-col xs="10" md="10">
              <div class="alert-warning-actions">
                <slot name="buttons"></slot>
                <v-btn v-if="confirmDefaultButtons" outlined color='primary' @click="close()" class="mr-2">
                  Cancelar
                </v-btn>
                <v-btn v-if="confirmDefaultButtons" color='primary' dark @click="confirm()">
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  {{confirmActionLabel}}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>

import Vue from 'vue';
import DangerSign from '@/components/utils/dialog/dialogSign/DangerSign';
import WarningSign from '@/components/utils/dialog/dialogSign/WarningSign';

export default Vue.extend({
  name: 'ConfirmDialog',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.showForm;
      },
    },
  },
  props: {
    showForm: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    dialogWidth: {
      type: String,
      default: '500',
    },
    dialogType: {
      type: String,
      required: true,
    },
    confirmActionLabel: {
      type: String,
    },
    confirmDefaultButtons: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DangerSign,
    WarningSign,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
});
</script>

<style scoped>

</style>
