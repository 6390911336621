<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">
        <g id="Grupo_2015" data-name="Grupo 2015" transform="translate(-10781 9953)">
            <path id="Caminho_1840" data-name="Caminho 1840" d="M75,0A75,75,0,1,1,0,75,75,75,0,0,1,75,0Z" transform="translate(10781 -9953)"
                :fill="this.rgbaSignBorderColor"/>
            <circle id="Elipse_62" data-name="Elipse 62" cx="60" cy="60" r="60" transform="translate(10796 -9938)" :fill="this.hexSignCoreColor"/>
            <g id="Grupo_1769" data-name="Grupo 1769" transform="translate(17.432 84.149)">
            <g id="Grupo_1766" data-name="Grupo 1766" transform="translate(10833.571 -9991.779)">
                <path id="Caminho_2694" data-name="Caminho 2694"
                    d="M239.187,173.005c-3.15,0-5.609,1.69-5.609,4.687,0,9.144,1.076,22.283,1.076,31.427,0,2.382,2.075,3.381,4.534,3.381,1.844,0,4.457-1,4.457-3.381,0-9.144,1.076-22.283,1.076-31.427C244.719,174.7,242.184,173.005,239.187,173.005Z"
                    transform="translate(-233.578 -173.005)" fill="#fff"/>
            </g>
            <g id="Grupo_1768" data-name="Grupo 1768" transform="translate(10833.341 -9946.982)">
                <path id="Caminho_2695" data-name="Caminho 2695" d="M238.568,353.306a5.917,5.917,0,1,0,0,11.833,5.917,5.917,0,0,0,0-11.833Z"
                    transform="translate(-232.651 -353.306)" fill="#fff"/>
            </g>
            </g>
        </g>
    </svg>
</template>

<script>

export default {
  name: 'BaseDialogSign',
  props: {
    rgbaSignBorderColor: {
      type: String,
      required: true,
    },
    hexSignCoreColor: {
      type: String,
      required: true,
    },
  },
};
</script>
