<template>
  <FormContent :selected="selected" class="pa-6">
    <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="addManual()" v-show="!readOnly">
      + PRODUTO RELACIONADO
    </v-btn>
    <DataCrud
      :table-header="header"
      :tableData="form.relatedProduct"
      :justify-center="false"
      :enableAddFunction="!readOnly"
      :enableEditFunction="!readOnly"
      :enableRemoveFunction="!readOnly"
      :showFormDialog="true"
      @editEvent="addManual"
      @formDelete="removeItem"
      :column-decorators="decorators"
      :server-items-length="form.relatedProductCodes.length"
    >
    </DataCrud>
    <v-dialog v-model="formDialog" max-width="70%">
      <v-card class="dialog-content">
        <v-card-title style="padding-top: 20px;">
          <h3 data-testid="page-title" class="description">
            Cadastro de produto relacionado
          </h3>
          <v-spacer />
          <v-btn color="#07BEE7" icon dark @click.stop="formDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-row class="margin-fix pa-10 pb-3">
            <v-col cols="12" class="pb-0">
              <Select2Input
                item-text="name"
                item-value="id"
                label="Produto"
                label-width="20%"
                placeholder="Selecione o produto"
                :qtdTotal="this.qtdTotalProduto"
                :search="searchProduct"
                v-model="item.product"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 0 30px 30px 30px;">
          <v-btn color="#07BEE7" v-show="false" outlined>Excluir</v-btn>
          <v-spacer />
          <v-btn color="#07BEE7" outlined @click.stop="cancelItem">Cancelar</v-btn>
          <v-btn color="#07BEE7" :disabled="!item.product" outlined @click.stop="addItem">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import DataCrud from '@/components/template/crud/DataCrud';
import { get } from '@/service/api';
import Select2Input from '@/components/template/form/input/Select2Input';
import ProductFormMixin from './mixins/ProductFormMixin';

export default {
  mixins: [ProductFormMixin],
  components: {
    FormContent,
    Select2Input,
    DataCrud,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Produtos Relacionados',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },

  data() {
    return {
      selected: false,
      formDialog: false,
      editing: false,
      qtdTotalProduto: null,
      item: {
        product: null,
        status: false,
        value: null,
        discont: null,
      },
      header: [
        { text: 'ID', value: 'id' },
        { text: 'Produto', value: 'name' },
      ],
      decorators: [
        {
          column: 'id',
          sortable: false,
        },
        {
          column: 'name',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async searchProduct(param, filter) {
      try {
        const data = await get(`ui-integrator/products/fetch?size=50&order_by=createdAt&sort_by=DESC${param ? `&filter=${filter} = !${param}` : ''}`);
        this.qtdTotalProduto = data.totalRegister;
        return data.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    addItem() {
      this.form.relatedProductCodes.push(this.item.product);
      this.item = {
        product: null,
      };
      this.$forceUpdate();
      this.init();
      this.formDialog = false;
    },
    addManual() {
      this.item = {
        product: null,
      };
      this.formDialog = true;
    },
    cancelItem() {
      this.formDialog = false;
    },
    async removeItem(item) {
      const index = this.form.relatedProductCodes.indexOf(item.id);
      this.form.relatedProductCodes.splice(index, 1);
      await this.init();
    },
    async init() {
      this.form.relatedProduct = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.form.relatedProductCodes.length; i++) {
        const value = this.form.relatedProductCodes[i];
        // eslint-disable-next-line no-await-in-loop,
        const { data } = await get(`ui-integrator/products/${value}`);
        this.form.relatedProduct.push(data);
      }
      this.$forceUpdate();
    },
  },
  async mounted() {
    await this.searchProduct();
    await this.init();
  },
};
</script>
