<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <TextFieldInput label-width="40%" v-model="form.code"
                        inputType="text"
                        label='Código*'
                        errorMessage="Campo obrigatório"
                        placeHolder='Informe o código do produto'
                        @focusout="validate('code')"
                        :validationRules='[validationRules.code]'
                        :disabled="!!form.id"
                        :readOnly="readOnly"
                        />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <ComboboxInput paddingLeft="5%" label-width="20%"
                       v-model="form.status"
                       persistent-hint
                       label='Status'
                       placeHolder='Selecione ou digite'
                       label-value="name"
                       item-value="value"
                       :options='availableStatus'
                       :readOnly="readOnly"
                       />
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col xl="5" lg="5" sm="12" md="5" class='pb-0'>
        <SwitchInput label-width="40%" label='Produto Kit?' v-model="form.productKit" :readOnly="readOnly"/>
      </v-col>
      <v-col xl="5" lg="5" sm="12" md="5" class='pb-0'>
        <SwitchInput label-width="40%" label='Somente retira loja?' v-model="form.storePickupOnly" :readOnly="readOnly"/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <TextFieldInput inputType="text" label-width="20%" v-model="form.name" label='Nome*' placeHolder='Informe o código do produto'
                        @focusout="validate('name')"
                        :validationRules='[validationRules.name]'
                        :readOnly="readOnly"
                        />
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <TextFieldInput label-width="20%" v-model="form.brand.name" label='Marca*' placeHolder='Informe a marca do produto'
                        @focusout="validate('brand.name')"
                        :validationRules="[validationRules['brand.name']]"
                        :readOnly="readOnly"
                        />
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <Select2Input item-text="name"
                      item-value="code"
                      label="Tipo de produto*"
                      label-width="20%"
                      :search="searchProductType"
                      :search-id="getProductType"
                      v-model="form.productTypeId"
                      :validationRules='[validationRules.productTypeId]'
                      @input="setProductTypeVersion"
                      @focusout="validate('productTypeId')"
                      :readOnly="readOnly"
                      />
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <Select2Input item-text="name"
                      item-value="id"
                      label="Categorias"
                      label-width="20%"
                      :multiple="true"
                      placeholder="Selecione a categoria"
                      :qtdTotal=this.qtdTotalCategory
                      :search="searchCategorys"
                      :search-id="getCategory"
                      v-model="form.categories"
                      :readOnly="readOnly"
                      />
      </v-col>
    </v-row>

    <v-row class='margin-fix pa-3 pb-0'>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <Select2Input item-text="name"
                      item-value="code"
                      label="Tipo modal"
                      label-width="40%"
                      placeholder="Selecione o tipo modal"
                      :search="searchModal"
                      v-model="form.modalType"
                      :readOnly="readOnly"
                      />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <ComboboxInput paddingLeft="5%" label-width="20%"
                       v-model="form.origin"
                       persistent-hint
                       label='Informe a Origen CST*'
                       placeHolder='Selecione ou digite'
                       label-value="description"
                       :item-value="null"
                       @focusout="validate('origin.description')"
                       :validationRules="[validationRules['origin.description']]"
                       :options='cstOptions'
                       :readOnly="readOnly"
                       />
      </v-col>
    </v-row>

    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <Select2Input item-text="name"
                      item-value="type"
                      label-width="20%"
                      v-model="form.sellerServices"
                      label="Serviços do produto"
                      :multiple="true"
                      :search="searchServices"
                      :readOnly="readOnly"
                      >
        </Select2Input>
      </v-col>
    </v-row>

  </FormContent>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import { get } from '@/service/api';
import Select2Input from '../../../components/template/form/input/Select2Input';
import ProductFormMixin from './mixins/ProductFormMixin';
import { listCategory, getCategory } from '../../../service/category/category-service';
import { listModalTypes } from '../../../service/modal-types/modal-types-service';
import { CstOptions } from '../../../static-data/CstOptions';
import { getProductTypes, listProductTypes } from '../../../service/product-types/product-types-service';

export default {
  mixins: [ProductFormMixin],
  components: {
    FormContent,
    Select2Input,
    TextFieldInput,
    SwitchInput,
    ComboboxInput,
  },
  props: {
    form: { type: Object, required: true },
    title: { type: String, required: false, default: 'Informações Gerais' },
    validationRules: { type: Object, default: () => ({}) },
    availableStatus: { type: Array },
    readOnly: { type: Boolean, default: false },
  },
  methods: {
    async getProductType(id) {
      const { data } = await getProductTypes(id);
      return data;
    },
    async searchProductType(param, filter, itemsPerPage) {
      const data = await listProductTypes(param, filter, itemsPerPage);
      return data.register;
    },
    async getCategory(id, plataforma = 'occ') {
      const { data } = await getCategory(id, plataforma);
      return data;
    },
    async searchCategorys(param, filter, itemsPerPage) {
      const data = await listCategory(param, filter, itemsPerPage);
      return data.register;
    },
    async searchModal(param, filter, itemsPerPage) {
      const { data } = await listModalTypes(param, filter, itemsPerPage);
      return data.register;
    },
    async searchServices(param, filter, itemsPerPage) {
      const { data } = await get(`ui-integrator/services/fetch?size=${itemsPerPage ? `${itemsPerPage}` : '20'}&filter=${param ? `${filter} = !${param}` : ''}`);
      return data.register;
    },
    async setProductTypeVersion(productType) {
      if (productType) {
        const { data } = await get(`ui-integrator/product-types/${productType}`);
        this.form.productTypeVersion = data.version;
        this.form.productTypeId = productType;
      }
    },
    validate(field) {
      if (field.includes('.')) {
        this.$emit('validateRequired', 'GeneralDataProductForm', field, this.form[field.split('.')[0]][field.split('.')[1]]);
      } else {
        this.$emit('validateRequired', 'GeneralDataProductForm', field, this.form[field]);
      }
    },
  },

  data() {
    return {
      categoriesForm: [],
      modalTypeForm: [],
      qtdTotalCategory: null,
      qtdTotalServices: null,
      selected: true,
      errors: {},
      icon: 'mdi-cube-outline',
      productTypeOptions: [],
      cstOptions: CstOptions,
    };
  },
  async mounted() {
    await this.searchProductType();
  },
};

</script>

<style>
</style>
