<template>
  <FormContent :selected="selected" class="pa-6">
    <v-row class="margin-fix pa-3 pb-0">
      <h3 class="subtext subtext_form_devide_h4">Dimensões e peso do produto</h3>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
        <TextFieldInput v-model="form.width" label-width="20%" label="Largura" placeHolder="Informe em cm" :readOnly="readOnly" />
      </v-col>
      <v-col cols="1">
        <v-spacer />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput v-model="form.length" label-width="30%" label="Comprimento" placeHolder="Informe em cm" :readOnly="readOnly" />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
        <TextFieldInput v-model="form.height" label-width="20%" label="Altura" placeHolder="Informe em cm" :readOnly="readOnly" />
      </v-col>
      <v-col cols="1">
        <v-spacer />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput v-model="form.weight" label-width="30%" label="Peso" placeHolder="Informe em Kg" :readOnly="readOnly" />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <h3 class="subtext subtext_form_devide_h4">Dimensões e peso do produto embalado</h3>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
        <TextFieldInput
          v-model="form.packageInfo.width"
          @focusout="validate('packageInfo.width')"
          :validationRules="[validationRules['packageInfo.width']]"
          label-width="20%"
          label="Largura*"
          placeHolder="Informe em cm"
          :readOnly="readOnly"
        />
      </v-col>
      <v-col cols="1">
        <v-spacer />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          v-model="form.packageInfo.length"
          @focusout="validate('packageInfo.length')"
          :validationRules="[validationRules['packageInfo.length']]"
          label-width="30%"
          label="Comprimento*"
          placeHolder="Informe em cm"
          :readOnly="readOnly"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
        <TextFieldInput
          v-model="form.packageInfo.height"
          @focusout="validate('packageInfo.height')"
          :validationRules="[validationRules['packageInfo.height']]"
          label-width="20%"
          label="Altura*"
          placeHolder="Informe em cm"
          :readOnly="readOnly"
        />
      </v-col>
      <v-col cols="1">
        <v-spacer />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          v-model="form.packageInfo.weight"
          @focusout="validate('packageInfo.weight')"
          :validationRules="[validationRules['packageInfo.weight']]"
          label-width="30%"
          label="Peso*"
          placeHolder="Informe em Kg"
          :readOnly="readOnly"
        />
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ProductFormMixin from './mixins/ProductFormMixin';

export default {
  mixins: [ProductFormMixin],
  components: {
    FormContent,
    TextFieldInput,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Dimensões e Pesos',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },

  data() {
    return {
      icon: 'mdi-cube-outline',
      selected: false,
    };
  },
  methods: {
    validate(field) {
      if (field.includes('.')) {
        this.$emit('validateRequired', 'DimensionsAndWeightProductForm', field, this.form[field.split('.')[0]][field.split('.')[1]]);
      } else {
        this.$emit('validateRequired', 'DimensionsAndWeightProductForm', field, this.form[field]);
      }
    },
  },
};
</script>

<style scoped>
.limit-height {
  max-height: 66px;
}
</style>
