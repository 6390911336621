export const CstOptions = [
  { id: 0, description: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8' },
  { id: 1, description: '1 - Estrangeira - Importação direta, exceto a indicada no código 6' },
  { id: 2, description: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7' },
  { id: 3, description: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% (quarenta por cento) e inferior ou igual a 70% (setenta por cento)' },
  { id: 4, description: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei no 288/67, e as Leis nos 8.248/91, 8.387/91, 10.176/01 e 11.484/07' },
  { id: 5, description: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% (quarenta por cento)' },
  { id: 6, description: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX e gás natural' },
  { id: 7, description: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural' },
];
export default CstOptions;
