<template>
  <FormContent :selected="selected" class="pa-6" background-color="transparent">
    <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
      <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="addSku()" v-show=!readOnly>
        + VARIAÇÃO
      </v-btn>
    </v-col>
    <DataCrud
      v-if="form.skus.length > 0"
      :column-decorators="decorators"
      :table-header="header"
      :tableData="form.skus"
      :enableEditFunction="true"
      :justify-center="false"
      :enableAddFunction="!readOnly"
      :enableRemoveFunction="!readOnly"
      :showFormDialog="true"
      @editEvent="edit"
      @formDelete="removeItem"
      :server-items-length="form.skus.length"
    >
    </DataCrud>
    <div v-else>
      <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">
        <p>
          Este produto ainda não possui variações de SKU cadastradas. <br />
          è necessario o cadastro de ao menos um SKU do produto.
        </p>
      </span>
    </div>
    <v-dialog v-model="formDialog" max-width="70%">
      <v-card class="dialog-content">
        <v-card-title style="padding-top: 20px;">
          <h3 data-testid="page-title" class="description">
            Cadastro de SKU
          </h3>
          <v-spacer />
          <v-btn color="#07BEE7" icon dark @click.stop="formDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-12">
          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
            <v-row class="margin-fix pa-3">
              <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                <TextFieldInput
                  v-model="item.id"
                  label="ID do SKU*"
                  placeHolder="Informe o código do SKU"
                  input-type="text"
                  labelWidth="40%"
                  :readOnly="readOnly"
                />
              </v-col>
              <v-col md="1"><span /></v-col>
              <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                <SwitchInput v-model="item.active" label="Status" labelWidth="20%" :readOnly="readOnly"/>
              </v-col>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <v-col cols="12" class="pb-0">
                <TextFieldInput
                  inputType="text"
                  label-width="20%"
                  v-model="item.title"
                  label="Título*"
                  placeHolder="Informe o titulo do SKU"
                  :readOnly="readOnly"
                />
              </v-col>
            </v-row>
            <v-row class="margin-fix pa-3">
              <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                <TextFieldInput
                  v-model="item.gtin"
                  label="Código GTIN"
                  placeHolder="Informe o código"
                  input-type="number"
                  labelWidth="40%"
                  :readOnly="readOnly"
                />
              </v-col>
              <v-col md="1"><span /></v-col>
              <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                <SwitchInput v-model="item.storePickup" label="Retira na loja?" labelWidth="20%" :readOnly="readOnly" />
              </v-col>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <h3 class="subtext subtext_form_devide_h4">Preço</h3>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                <TextFieldInput
                  label-width="40%"
                  v-model="item.price.list"
                  inputType="double"
                  :mask="mask"
                  label="Preço de listagem*"
                  prefix="R$"
                  :readOnly="readOnly"
                />
              </v-col>
              <v-col cols="1">
                <v-spacer />
              </v-col>
              <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                <TextFieldInput
                  label-width="20%"
                  v-model="item.price.sale"
                  inputType="double"
                  :mask="mask"
                  label="Preço de venda*"
                  prefix="R$"
                  :readOnly="readOnly"
                />
              </v-col>
            </v-row>

            <v-row class="margin-fix pa-3">
              <v-col xl="12" lg="12" sm="12" md="5" class="pb-0">
                <SwitchInput v-model="item.isPromotion" label="Promoção?" labelWidth="20%" :readOnly="readOnly" />
              </v-col>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <h3 class="subtext subtext_form_devide_h4">Propriedades do SKU</h3>
            </v-row>
            <div v-if="productType">
              <v-row class="margin-fix pa-3 pb-0">
                <v-col xl="12" lg="12" sm="12" md="12" class="pb-0" v-for="form in requiredProps(item.properties)" :key="form.code">
                  <TextFieldInput
                    label-width="20%"
                    v-model="form.value"
                    :typeof="form.type"
                    :label="`${form.label}*`"
                    :placeHolder="form.label"
                    :readOnly="readOnly"
                  />
                </v-col>
                <div v-if="requiredProps(item.properties).length === 0">
                  <h4>Não há "Propriedades do SKU" para o tipo de produto selecionado</h4>
                </div>
                <v-col cols="1">
                  <v-spacer />
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <h4>Ainda não há um tipo de produto selecionado</h4>
            </div>
            <v-row class="margin-fix pa-3 pb-0">
              <h4 class="subtext subtext_form_devide_h4">Especificações</h4>
            </v-row>
            <div v-if="productType">
              <v-row class="margin-fix pa-3 pb-0">
                <v-col xl="12" lg="12" sm="12" md="12" class="pb-0" v-for="form in optionsProps(item.properties)" :key="form.code">
                  <TextFieldInput
                    label-width="20%"
                    v-model="form.value"
                    :typeof="form.type"
                    :label="`${form.label}*`"
                    :placeHolder="form.label"
                    :readOnly="readOnly"
                  />
                </v-col>
                <div v-if="optionsProps(item.properties).length === 0">
                  <h4>Não há "Especificações" para o tipo de produto selecionado</h4>
                </div>
                <v-col cols="1">
                  <v-spacer />
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <h3>Ainda não há um tipo de produto selecionado</h3>
            </div>
            <v-row class="margin-fix pa-3 pb-0">
              <h3 class="subtext subtext_form_devide_h4">Estoque</h3>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                <TextFieldInput label-width="40%" v-model="item.stock.available" inputType="number" label="Disponivel*" :readOnly="readOnly" />
              </v-col>
              <v-col cols="1">
                <v-spacer />
              </v-col>
              <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                <TextFieldInput label-width="20%" v-model="item.stock.limit" inputType="number" label="Minimo para venda*" :readOnly="readOnly" />
              </v-col>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <h3 class="subtext subtext_form_devide_h4">Estoque para "Venda Futura"</h3>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                <TextFieldInput label-width="40%" v-model="item.stock.onDemand" inputType="number" label="Sob demanda" :readOnly="readOnly" />
              </v-col>
              <v-col cols="1">
                <v-spacer />
              </v-col>
              <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                <TextFieldInput label-width="20%" v-model="item.stock.backOrder" inputType="number" label="Pedido" :readOnly="readOnly" />
              </v-col>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0">
              <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                <TextFieldInput label-width="40%" v-model="item.stock.preOrder" inputType="number" label="Encomenda" :readOnly="readOnly" />
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions style="padding: 0 30px 30px 30px;">
          <v-btn color="#07BEE7" v-show="false" outlined :readOnly="readOnly">Excluir</v-btn>
          <v-spacer />
          <v-btn color="#07BEE7" outlined @click.stop="formDialog = false">Cancelar</v-btn>
          <v-btn color="#07BEE7" outlined @click.stop="save" :disabled="validSaveSku || readOnly">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import { get } from '../../../service/api';
import DataCrud from '../../../components/template/crud/DataCrud';
import notification from '../../../service/notification';

export default {
  components: {
    FormContent,
    DataCrud,
    TextFieldInput,
    SwitchInput,
  },
  props: {
    idProductType: {
      type: [Object, String],
    },
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'SKUS',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    mask: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false },
  },
  computed: {
    validSaveSku() {
      return (
        !this.item.id ||
        !this.item.title ||
        !this.item.stock ||
        !this.item.stock.available ||
        this.item.stock.available <= 0 ||
        !this.item.stock.limit ||
        this.item.stock.limit <= 0 ||
        !this.item.price.sale ||
        this.item.price.sale <= 0 ||
        !this.item.price.list ||
        this.item.price.list <= 0
      );
    },
  },
  data() {
    return {
      productType: null,
      selected: false,
      editing: false,
      formDialog: false,
      item: {
        price: {},
        stock: {},
        properties: [],
      },
      decorators: [
        {
          column: 'active',
          iconFunction: (item) => {
            if (item.active) {
              return 'mdi-check';
            }
            return 'mdi-cancel';
          },
          iconColorFunction: (item) => {
            if (item.active) {
              return '#02D13F';
            }
            return 'orange';
          },
        },
        {
          column: 'error',
          iconFunction: (item) => {
            if (!item.error) {
              return 'mdi-check';
            }
            return 'mdi-cancel';
          },
          iconColorFunction: (item) => {
            if (!item.error) {
              return 'green';
            }
            return 'orange';
          },
        },
        {
          column: 'price.sale',
          type: 'text',
          formatterFunction: (item) => item.price.sale.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        },
      ],
      header: [
        { text: 'ID', value: 'id' },
        { text: 'Título', value: 'title' },
        { text: 'Preço venda', value: 'price.sale' },
        { text: 'Status', value: 'active' },
        { text: 'Valido', value: 'error' },
      ],
    };
  },
  watch: {
    idProductType: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    optionsProps(properties) {
      return properties?.filter((value) => !value?.critical);
    },
    requiredProps(properties) {
      return properties?.filter((value) => value?.critical);
    },
    validate() {
      this.$emit('validateRequired', 'SKUVariationSubProductForm', 'length');
      this.$emit('validateRequired', 'SKUVariationSubProductForm', 'properties', this.form.skus);
    },
    addSku() {
      this.item = {
        price: {},
        stock: {},
        gtin: null,
        available: null,
        storePickup: false,
        bundleSpecification: [],
        properties: [],
      };
      this.productType.skuProperties.forEach((value) => {
        if (value.code) {
          this.item.properties.push({
            id: value.code,
            label: value.name,
            critical: value.critical,
            type: value.type,
            value: null,
          });
        }
      });
      this.editing = false;
      this.formDialog = true;
      this.validate();
    },
    save() {
      if (this.editing) {
        this.form.skus = this.form.skus.filter((value) => this.item.id !== value.id);
      } else if (this.form.skus.filter((value) => this.item.id === value.id).length > 0) {
        notification('Já exite um SKU com o ID informado', 'error');
        return;
      }
      this.form.skus.push(this.item);
      this.formDialog = false;
      this.validate();
      this.$forceUpdate();
    },
    edit(item) {
      this.item = { ...item };
      this.editing = true;
      this.formDialog = true;
    },
    removeItem(item) {
      const index = this.form.skus.indexOf(item.id);
      this.form.skus.splice(index, 1);
      this.validate();
    },
    async init() {
      if (this.idProductType && typeof this.idProductType === 'string') {
        const { data } = await get(`ui-integrator/product-types/${this.idProductType}`);
        this.productType = data;
        this.form.skus.forEach((sku) => {
          const refList = [];
          sku.error = false;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < sku.properties.length; i++) {
            const value = sku.properties[i];
            // eslint-disable-next-line vue/max-len
            if (this.productType.skuProperties.filter((prop) => prop.code === value.id).length > 0) {
              refList.push(value);
            }
          }
          sku.properties = refList;
          this.productType.skuProperties.forEach((value) => {
            if (value.code) {
              let ref = false;
              sku.properties.forEach((propertiesForm) => {
                if (propertiesForm.id === value.code) {
                  propertiesForm.type = value.type;
                  propertiesForm.critical = value.critical;
                  ref = true;
                }
              });
              if (!ref) {
                sku.properties.push({
                  id: value.code,
                  label: value.name,
                  critical: value.critical,
                  type: value.type,
                  value: null,
                });
              }
            }
          });
        });
        this.validate();
        this.$forceUpdate();
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
