<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-btn color='#07BEE7'
           style='color: #FFFFFF; float: right' depressed @click='add()' v-show="!readOnly">
      + SELO
    </v-btn>
    <DataCrud
      :table-header="header"
      :tableData="form.stamps"
      :justify-center="false"
      :enableAddFunction='!readOnly'
      :enableEditFunction='true'
      :enableRemoveFunction='!readOnly'
      :showFormDialog="true"
      @editEvent="edit"
      @formDelete="removeItem"
      :column-decorators="decorators"
      :server-items-length="form.stamps.length"
    >
    </DataCrud>
    <v-dialog v-model='formDialog' max-width='70%'>
      <v-card class="dialog-content">
        <v-card-title style='padding-top: 20px;'>
          <h3 data-testid='page-title' class='description'>
            Cadastro de selo
          </h3>
          <v-spacer/>
          <v-btn color='#07BEE7' icon dark @click.stop='formDialog = false'>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pa-12'>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col cols='12' class='pb-0'>
              <ComboboxInput label-h-align="center" label-width="20%"
                             v-model="item.type"
                             persistent-hint
                             label='Tipo do selo'
                             placeHolder='Selecione o tipo de selo'
                             :options='typeOptions'
                             :readOnly="readOnly"/>
            </v-col>
          </v-row>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col cols='12' class='pb-0'>
              <TextFieldInput label-h-align="center"
                             labelWidth='20%'
                             v-model="item.description"
                             label='Descrição'
                             placeHolder='Informe a descrição do selo'
                             :validationRules='[validationRules.description]'
                             :maxLength="20"
                             :readOnly="readOnly" />
            </v-col>
          </v-row>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col cols='12' class='pb-0'>
              <TextFieldInput label-h-align="center" label-width="20%" type="number" v-model="item.value" label='Valor'
                              placeHolder='Informe o valor exibido no selo'
                              :readOnly="readOnly"/>
            </v-col>
          </v-row>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
              <h3 style="width: 20%;text-align: center" class="subtext subtext_form_devide_h4">Propriedades</h3>
            </v-col>
          </v-row>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
              <ComboboxInput label-width="40%"
                             v-model="item.priority"
                             label-h-align="center"
                             persistent-hint
                             label='Prioridade'
                             placeHolder='Selecione uma prioridade'
                             label-value="name"
                             item-value="value"
                             :options='priorityOptions'
                             :readOnly="readOnly"/>
            </v-col>
            <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
              <ComboboxInput label-width="40%"
                             v-model="item.background"
                             label-h-align="center"
                             persistent-hint
                             label='Cor de fundo'
                             placeHolder='Selecione a cor'
                             :options='collorOptions'
                             :readOnly="readOnly"/>
            </v-col>
          </v-row>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
              <h3 style="width: 20%;text-align: center" class="subtext subtext_form_devide_h4">Vencimento</h3>
            </v-col>
          </v-row>
          <v-row class='margin-fix pa-3 pb-0'>
            <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
              <TextFieldInput label-h-align="center" v-model="item.initialExpirationDate" inputType='date' label='Data inicial'
                              placeHolder='Informe a data'
                              labelWidth='40%'
                              :readOnly="readOnly"/>
            </v-col>
            <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
              <TextFieldInput label-h-align="center"
                              v-model="item.finalExpirationDate"
                              label='Data final'
                              placeHolder='Informe a data'
                              inputType='date'
                              labelWidth='40%'
                              :readOnly="readOnly"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style='padding: 0 30px 30px 30px;'>
          <v-btn color='#07BEE7' v-show="false" outlined :v-show="readOnly">Excluir</v-btn>
          <v-spacer/>
          <v-btn color='#07BEE7' outlined @click.stop='cancelItem'>Cancelar</v-btn>
          <v-btn color='#07BEE7' outlined @click.stop='addItem' :disabled="!item.type" :v-show="readOnly">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormContent>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import ProductFormMixin from './mixins/ProductFormMixin';
import DataCrud from '../../../components/template/crud/DataCrud';
import ComboboxInput from '../../../components/template/form/input/ComboboxInput';
import TextFieldInput from '../../../components/template/form/input/TextFieldInput';

export default {
  mixins: [ProductFormMixin],
  components: {
    FormContent,
    TextFieldInput,
    ComboboxInput,
    DataCrud,
  },

  props: {
    form: { type: Object, required: true },
    title: { type: String, required: false, default: 'Selo' },
    validationRules: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: false,
      formDialog: false,
      editing: false,
      collorOptions: [
        'amarelo', 'branco', 'vermelho', 'chumbo', 'preto',
      ],
      priorityOptions: [
        { name: '1 - Irrelevante', value: 1 },
        { name: '2 - Extremamente baixa', value: 2 },
        { name: '3 - Muita baixa', value: 3 },
        { name: '4 - Moderadamente baixa', value: 4 },
        { name: '5 - Baixa', value: 5 },
        { name: '6 - Intermediária', value: 6 },
        { name: '7 - Moderadamente alta', value: 7 },
        { name: '8 - Alta', value: 8 },
        { name: '9 - Muito alta', value: 9 },
        { name: '10 - Extremamente alta', value: 10 },
      ],
      typeOptions: [
        'juros', 'blackfriday', 'desconto', 'lancamento', 'frete',
      ],
      item: {},
      ref: {},
      header: [
        { text: 'ID', value: 'id' },
        { text: 'Tipo', value: 'type' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor', value: 'value' },
        { text: 'Prioridade', value: 'priority' },
        { text: 'Data final', value: 'finalExpirationDate' },
      ],
      decorators: [
        {
          column: 'priority',
          formatterFunction: (item) => {
            if (item.priority) {
              return this.priorityOptions.filter((priority) => priority.value === item.priority)[0].name;
            }
            return '';
          },
        },
      ],
    };
  },
  methods: {
    addItem() {
      if (this.item.priority) {
        this.item.priority = this.item.priority.value;
      }
      if (!this.editing) {
        this.item.id = this.form.stamps.length + 1;
        this.form.stamps.push(this.item);
      } else {
        this.form.stamps.splice(this.item.id - 1, 1, this.item);
      }
      this.formDialog = false;
      this.$forceUpdate();
    },
    add() {
      this.item = {};
      this.editing = false;
      this.formDialog = true;
    },
    edit(item) {
      this.item = { ...item };
      if (this.item.priority) {
        // eslint-disable-next-line prefer-destructuring
        this.item.priority = this.priorityOptions.filter((priority) => priority.value === item.priority)[0];
      }
      this.editing = true;
      this.formDialog = true;
    },
    cancelItem() {
      this.formDialog = false;
    },
    removeItem(item) {
      const index = this.form.stamps.indexOf(item.id);
      this.form.stamps.splice(index, 1);
    },
  },
};

</script>
