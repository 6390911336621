<template>
  <div>
    <FormContent :selected="selected" class="pa-6">
      <div>
        <v-row>
          <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">Propriedades do produto</span>
        </v-row>
        <v-row class="margin-fix pa-3 pb-0">
          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
            <TextFieldInput
              label-width="20%"
              @focusout="validate('warrantyTime', form.warrantyTime)"
              :validationRules="[validationRules.rules.warrantyTime]"
              v-model="form.warrantyTime"
              typeof="number"
              label="Garantia do fabricante*"
              placeHolder="Informe quantos meses"
              :readOnly="readOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-spacer />
          </v-col>
        </v-row>
      </div>
      <div v-if="productType">
        <v-row class="margin-fix pa-3 pb-0">
          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0" v-for="form in requiredProps" :key="form.code">
            <TextFieldInput
              label-width="20%"
              @focusout="validate(form.id, form.value)"
              :validationRules="[validationRules.rules[form.id]]"
              v-model="form.value"
              :typeof="form.type"
              :label="`${form.label}*`"
              :placeHolder="form.label"
              :readOnly="readOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-spacer />
          </v-col>
        </v-row>
        <v-row v-if="optionsProps.length > 0">
          <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">Especificações</span>
        </v-row>
        <v-row class="margin-fix pa-3 pb-0">
          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0" v-for="form in optionsProps" :key="form.code">
            <TextFieldInput
              label-width="20%"
              v-model="form.value"
              :typeof="form.type"
              :label="`${form.label}`"
              :placeHolder="form.label"
              :readOnly="readOnly"
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        {{ productType }}
        <h3>Ainda não há um tipo de produto selecionado</h3>
      </div>
    </FormContent>
  </div>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import { get } from '../../../service/api';
import ProductFormMixin from './mixins/ProductFormMixin';

export default {
  mixins: [ProductFormMixin],
  components: {
    FormContent,
    TextFieldInput,
  },

  props: {
    idProductType: {
      type: [Object, String],
    },
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Propriedades',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },

  data() {
    return {
      selected: false,
      productType: null,
      icon: 'mdi-cube-outline',
    };
  },
  computed: {
    optionsProps() {
      return this.form?.properties?.filter((value) => !value?.critical);
    },
    requiredProps() {
      return this.form?.properties?.filter((value) => value?.critical);
    },
  },
  watch: {
    idProductType: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    validate(field, value) {
      this.$emit('validateRequired', 'PropertiesAndSpecificationProductForm', field, value);
    },
    async init() {
      if (this.idProductType && typeof this.idProductType === 'string') {
        const { data } = await get(`ui-integrator/product-types/${this.idProductType}`);
        this.productType = data;
        const refList = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.properties.length; i++) {
          const value = this.form.properties[i];
          if (this.productType.properties.filter((prop) => prop.code === value.id).length > 0) {
            refList.push(value);
          }
        }
        this.form.properties = refList;
        this.productType.properties.forEach((value) => {
          let ref = false;
          this.form.properties.forEach((propertiesForm) => {
            if (propertiesForm.id === value.code) {
              propertiesForm.type = value.type;
              propertiesForm.critical = value.critical;
              ref = true;
            }
          });
          if (value.critical) {
            this.validationRules.rules[value.code] = (item) => !!item || `Campo \`${value.name}\` é Obrigatório.`;
            this.validationRules.required[value.code] = true;
          }
          if (!ref) {
            this.form.properties.push({
              id: value.code,
              label: value.name,
              critical: value.critical,
              type: value.type,
              code: value.code,
              value: null,
            });
          }
        });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.background-default {
  background-color: rgba(245, 245, 246, 255) !important;
}
</style>
