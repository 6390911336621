<template>
  <FormContent :selected="selected" class="pa-6">
    <v-row class="margin-fix pa-3 pb-0">
      <v-col cols="12" class="pb-0">
        <TextAreaInput
          v-model="form.description"
          @focusout="validate('description')"
          :validationRules="[validationRules.description]"
          label="Descrição*"
          placeHolder="Informe a descrição do produto"
          :readOnly="readOnly"
        />
      </v-col>
    </v-row>
    <v-row>
      <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">
        Informações do manual de instruções do produto
      </span>
    </v-row>
    <v-row>
      <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="addManual(null)" v-show="!readOnly">
        + Manual
      </v-btn>
    </v-row>
    <v-row>
      <DataCrud
        :table-header="header"
        :tableData="form.manual"
        :justify-center="false"
        :enableEditFunction="!readOnly"
        :enableRemoveFunction="!readOnly"
        :showFormDialog="true"
        @editEvent="addManual"
        @formDelete="removeItem"
        :column-decorators="decorators"
        :server-items-length="form.manual.length"
      >
      </DataCrud>
    </v-row>
    <v-dialog v-model="formDialog" max-width="70%">
      <v-card class="dialog-content">
        <v-card-title style="padding-top: 20px;">
          <h1 data-testid="page-title" class="description">
            Informações do manual de instruções do produto
          </h1>
          <v-spacer />
          <v-btn color="#07BEE7" icon dark @click.stop="formDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="margin-fix pa-3 pb-0">
            <v-col cols="12" class="pb-0">
              <TextFieldInput
                label-width="20%"
                v-model="item.title"
                inputType="text"
                label-h-align="center"
                label-v-align="center"
                label="Título*"
                errorMessage="Campo obrigatório"
                placeHolder="Informe o título"
              />
            </v-col>
          </v-row>
          <v-row class="margin-fix pa-3 pb-0">
            <v-col cols="12" class="pb-0">
              <TextFieldInput
                label-width="20%"
                v-model="item.url"
                inputType="text"
                label-h-align="center"
                label-v-align="center"
                label="URL*"
                errorMessage="Campo obrigatório"
                placeHolder="Informe a URL"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 0 30px 30px 30px;">
          <v-btn color="#07BEE7" v-show="false" outlined>Excluir</v-btn>
          <v-spacer />
          <v-btn color="#07BEE7" outlined @click.stop="cancelItem">Cancelar</v-btn>
          <v-btn color="#07BEE7" :disabled="!item.url || !item.title" outlined @click.stop="addItem">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import TextAreaInput from '@/components/template/form/input/TextAreaInput';
import DataCrud from '@/components/template/crud/DataCrud';
import ProductFormMixin from './mixins/ProductFormMixin';

export default {
  mixins: [ProductFormMixin],
  components: {
    DataCrud,
    FormContent,
    TextFieldInput,
    TextAreaInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Descrição e Manual',
    },
    form: {
      type: Object,
      required: true,
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      item: {
        url: null,
        title: null,
      },
      selected: false,
      icon: 'mdi-cube-outline',
      editing: false,
      header: [
        { text: 'Título', value: 'title' },
        { text: 'URL', value: 'url' },
      ],
      decorators: [
        {
          column: 'title',
          width: '60%',
          sortable: false,
        },
        {
          column: 'url',
          width: '40%',
          sortable: false,
        },
      ],
      formDialog: false,
    };
  },
  methods: {
    removeItem(item) {
      const index = this.form.manual.indexOf(item);
      this.form.manual.splice(index, 1);
    },
    addItem() {
      if (!this.editing) {
        this.form.manual.push(this.item);
      }
      this.item = {
        url: null,
        title: null,
      };
      this.$forceUpdate();
      this.formDialog = false;
    },
    cancelItem() {
      this.item = {
        url: null,
        title: null,
      };
      this.formDialog = false;
    },
    addManual(item) {
      if (item) {
        this.item = item;
        this.editing = true;
      } else {
        this.editing = false;
        this.item = {
          url: null,
          title: null,
        };
      }
      this.formDialog = true;
    },
    validate(field) {
      if (field.includes('.')) {
        this.$emit('validateRequired', 'DescriptionProductForm', field, this.form[field.split('.')[0]][field.split('.')[1]]);
      } else {
        this.$emit('validateRequired', 'DescriptionProductForm', field, this.form[field]);
      }
    },
  },
};
</script>

<style>
.description_form_subtext {
  text-align: left;
  font: normal normal normal 18px/21px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  color: #6c757d;
  opacity: 1;
}
</style>
