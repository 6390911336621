<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <TextFieldInput v-model="form.seoMetaInfo.seoTitle" label='Título' labelWidth='20%' placeHolder='Digite o título' :readOnly="readOnly"/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <TextAreaInput
          label='Descrição'
          v-model="form.seoMetaInfo.seoDescription"
          labelWidth='20%'
          placeHolder='Digite a descrição'
          :readOnly="readOnly"/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <TextFieldInput
          label='Palavaras-chave'
          v-model="form.seoMetaInfo.seoKeywords"
          labelWidth='20%'
          placeHolder='Digite as palavras-chave e tecle enter'
          :readOnly="readOnly"/>
      </v-col>
    </v-row>

    <v-row class='margin-fix pa-3 pb-0'>
      <v-col cols='12' class='pb-0'>
        <TextFieldInput
          label='URL derivada'
          v-model="form.seoMetaInfo.seoUrlSlugDerived"
          labelWidth='20%'
          placeHolder='informe a URL derivada'
          :readOnly="readOnly"/>
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import TextAreaInput from '@/components/template/form/input/TextAreaInput';

export default Vue.extend({

  components: {
    TextAreaInput,
    FormContent,
    TextFieldInput,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Indexação',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    readOnly: { type: Boolean, default: false },
  },

  data() {
    return {
      selected: false,
    };
  },
});

</script>

<style>
</style>
